import Vue from 'vue'
import './set-public-path'
import axios from 'axios';
import App from './App.vue'
import VueI18n from "vue-i18n";
import router from "./router";
import commonMixin from '../src/assets/common/vue-common.js'
import ListingcommonMixin from '../src/assets/common/vue-commonList.js'
import breadcrumb from './components/Common/BreadCrumb.vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useloggedUserInfo } from '../src/stores/UserInfo';
//import VueCompositionAPI from '@vue/composition-api'
import singleSpaVue from 'single-spa-vue';
import Loader from './components/Common/Loader.vue'
import vuescroll from 'vuescroll';
const resourceFilePath = process.env.VUE_APP_LANGUAGE_FILE_URL;
const envType = process.env.VUE_APP_NODE_ENV;
import Vue2ColorPicker from 'vue2-color-picker';
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
// import DatePicker from 'v-calendar/src/components/DatePicker'
import ModuleHeader from '../src/components/Header/ModuleHeader.vue';
import {TgList} from 'tg-listv/dist/tglistv.common'
import { DynamicForm } from 'tg-controls_cli/dist/myLib.common'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import moment from 'moment';
var $ = require('jquery');
Vue.config.productionTip = false;
Vue.prototype.$= $;
require('./vee-validate');
 Vue.use(VueI18n);
 Vue.use(PiniaVuePlugin);
 Vue.component('loader', Loader)
 Vue.component('breadcrumb', breadcrumb);
 Vue.component('tg-list',TgList);
 Vue.component('dynamic-form',DynamicForm); 
 Vue.component('ModuleHeader',ModuleHeader);
 Vue.component('v-date-picker', DatePicker)
 Vue.use(Vue2ColorPicker);
 Vue.use(vuescroll); 
 const pinia=createPinia();
 window.pinia = pinia;
 Vue.use(pinia);
 let parsedLocalStorage = localStorage.getItem('TALGYEN_NAVBAR_SETTING');
let selectedLang = "en"; // Default to English

if (parsedLocalStorage) {
  const settings = JSON.parse(parsedLocalStorage);
  selectedLang = settings.lang.toLowerCase() || "en"; // Use stored language or default to English
}
const i18n = new VueI18n({
  locale: selectedLang,
  messages: {
  },
});

Vue.mixin(commonMixin, ListingcommonMixin);

const loadedLanguages = []

function setI18nLanguage(lang) {
  i18n.locale = lang
  // axios.defaults.headers.common['Accept-Language'] = lang
  // document.querySelector('html').setAttribute('lang', lang)
  // return lang
}

async function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }

  await axios.get(`${resourceFilePath}/${envType}/${lang}.js`).then(response => {
    let msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, response.data[0])
  })
}

// router.js
router.beforeEach((to, from, next) => {
  loadLanguageAsync(selectedLang).then(() => next());
})

const containerSelector = '#page-2-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
    router,
    render(h) {     
      return h(App, {
         props: {          
          props: {          
            userProfile:useloggedUserInfo().setUser(this.userProfile)
           } 
        } 
      });//
    },
    el: containerSelector
    
  }
});
Vue.filter('formatDateee', function (value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY');
  }
});
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('formatDateTime', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('MM/DD/YYYY h:mm A'))
}
})
Vue.filter('Time', function (value) {
  if (value) {
    return moment(value).format('h:mm A');
  }
});
Vue.filter('formatDateTimee', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY h:mm A');
  }
  return value; 
});
Vue.filter('formatnextDate', function (value) {
  if (value) {
      let nextDate = moment(value).add(1, 'days'); 
      return nextDate.format("MM/DD/YYYY hh:mm A"); 
  }
  return value;
});
Vue.filter('formatPreviousDate', function (value) {
  if (value) {
      let previousDate = moment(value).subtract(1, 'days'); 
      return previousDate.format("MM/DD/YYYY hh:mm A");
  }
  return value;
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
