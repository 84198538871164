import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
  return http.instanceCommon.get("/Common/GetContextMenu?"+params);
  }
  async SaveHideContextMenuConfig(param){
    return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?"+param);
  }
  async GetConfigurationValueForCompanyByKey(param){
    return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?"+param);
  }
  async GetHideContextMenuConfig(){
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async GetModuleMasterIdByModuleName(params){ 
    return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?"+params);
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetTrainingList(params) { 
    return await http.instance.get("/Training/GetTrainingList?" + params);
  }
  async IsValidForFeedBack(params) { 
    return await http.instance.get("/Training/IsValidForFeedBack?" + params);
  }
  async GetMSPPackagesListing(params) { 
    return await http.instance.get("/MSPPackage/GetMSPPackagesListing?" + params);
  }
  async Delete(params) {      
    return await http.instanceCommon.post("/Common/Delete?" + params );
  }
  async GetAttachments(params) { 
    return await http.instance.get("/Training/GetAttachments?" + params);
  }
  async GetIsTrainer(params) { 
    return await http.instance.get("/Training/GetIsTrainer?" + params);
  }
  async DownloadFile(params){
    return await http.instanceCommon.get("/Common/DownloadFile?"+params);
  }
  async DeleteAttachments(params) { 
    return await http.instance.get("/Training/DeleteAttachment?" + params);
  }
  async DeleteTraining(params) { 
    return await http.instance.get("/Training/DeleteTraining?" + params);
  }
  async UpdateTrainingStatus(params) { 
    return await http.instance.get("/Training/UpdateTrainingStatus?" + params);
  }
  async GetMSPSubscriptionList(params) { 
    return await http.instance.get("/MSPSubscription/GetMSPSubscriptionList?" + params);
  }
  async GetSubscriptionMSPPackeges(params) { 
    return await http.instance.get("/MSPPackage/GetSubscriptionMSPPackeges?" + params);
  }
  async MyProductsListing(params) { 
    return await http.instance.get("/MSPPackage/MyProductsListing?" + params);
  }
  async BindEventLocationByUser() {
    return await http.instance.get("/Training/BindEventLocationByUser");
  }
  async GetCompanySkills() {
    return await http.instance.get("/Training/GetCompanySkills");
  }
  async GetUsersListbyDepartment(params) { 
    return await http.instance.get("/Training/GetUsersListbyDepartment?" + params);
  }
  async GetDepartmentListbyCompany() {
    return await http.instance.get("/Training/GetDepartmentListbyCompany");
  }
  async DeleteSubscription(params) { 
    return await http.instance.get("/MSPSubscription/DeleteSubscription?" + params);
  }
  async BindReccurringList() {
    return await http.instance.get("/Training/BindReccurringList");
  }
  async UpdateRoleStatus(params) {
    return await http.instanceCommon.get("/Common/UpdateRoleStatus?" + params);
  }
  async GetEventLocationAvailabilityDetail(params) { 
    return await http.instance.get("/Training/GetEventLocationAvailabilityDetail?" + params);
  }
  async GetStatusList(params) {      
    return await http.instanceCommon.get("/Common/GetStatusList?" + params);
  }
  ADDUpdateSkill(params) {
    return http.instance.post("/Training/ADDUpdateSkill", { postString: params })
  }
  async UpdateStatus(params) {
    return await http.instance.post("/MSPSubscription/UpdateStatus?" + params);
  }
  AddTraining(params) {
    return http.instance.post("/Training/AddTraining", { postString: params })
  }
  async AddUpdateMSPPackage(params) {
    return await http.instance.post("/MSPPackage/AddUpdateMSPPackage", { postString: params });
  }
  async GetTrainingForEditById(params) { 
    return await http.instance.get("/Training/GetTrainingForEditById?" + params);
  }
  async GetCountryList() {
    return await http.instanceCommon.get("/HelpAndGuide/GetCountryList");
  }
  async GetPackageDetailsByPackageId(params) { 
    return await http.instance.get("/MSPPackage/GetPackageDetailsByPackageId?" + params);
  }
  async GetChildPackageId(params) { 
    return await http.instance.get("/MSPPackage/GetChildPackageId?" + params);
  }
  async GetPackageProductRelation(params) { 
    return await http.instance.get("/MSPPackage/GetPackageProductRelation?" + params);
  }
  async GetParentPackageImagePath(params) { 
    return await http.instance.get("/MSPPackage/GetParentPackageImagePath?" + params);
  }
  async GetUserList(params) { 
    return await http.instance.get("/MSPSubscription/GetUserList?" + params);
  }
  UpdateTraining(params) {
    return http.instance.post("/Training/UpdateTraining", { postString: params })
  }
  async GetTrainingDetail(params) { 
    return await http.instance.get("/Training/GetTrainingDetail?" + params);
  }
  async GetPackageList(params) { 
    return await http.instance.get("/MSPSubscription/GetAllPackage?" + params);
  }
  async GetFeedbackInterval(params) { 
    return await http.instance.get("/Training/GetFeedbackInterval?" + params);
  }
  SaveFeedbackIntervalValue(params) {
    return http.instance.post("/Training/SaveFeedbackInterval", { postString: params })
  }
  async GetConfigurationValueByKey(param) {
    return await http.instanceCommon.get("/Common/GetConfigurationValueByKey?" + param);
  }
  async GetSubscriptionDetailsBySubscriptionId(params) { 
    return await http.instance.get("/MSPSubscription/GetCustomSubscription?" + params);
  }
  async GetCustomCompanyInfo(params) { 
    return await http.instance.get("/MSPSubscription/GetCustomCompanyInfo?" + params);
  }
  async GetCustomInvoiceList(params) { 
    return await http.instance.get("/MSPSubscription/GetCustomInvoiceList?" + params);
  }
  async GetCustomPaymentHistoryList(params) { 
    return await http.instance.get("/MSPSubscription/GetCustomPaymentHistoryList?" + params);
  }
  async SaveAttachment(params) {
    return http.instance.post("/Training/SaveAttachment?", params);
  }
  async GetAttendanceListing(params) { 
    return await http.instance.get("/Training/GetAttendanceListing?" + params);
  }
  async GetUserAnswers(params) { 
    return await http.instance.get("/Training/GetUserAnswers?" + params);
  }
  AddUpdateAttendance(params) {
    return http.instance.post("/Training/AddUpdateAttendance", { postString: params })
  }
  async UpdateCompanyDetail(params) {
    return http.instance.post("/MSPSubscription/UpdateCompanyDetail?", params);
  }
  async GetINVOICES(params) {
    return http.instance.get("/MSPSubscription/GetINVOICES?" + params);
  }
  async SavePaymentHistory(params) {
    return await http.instance.post("/MSPSubscription/SavePaymentHistory", { postString: params });
  }
  async SavePackageAttachment(params) {
    return await http.instance.post("/MSPPackage/SavePackageAttachment?", params);
  }
  async GetInvoiceDetail(params) {
    return http.instance.get("/MSPSubscription/GetInvoiceDetail?" + params);
  }
  async GetTrainingMaterial(params) { 
    return await http.instance.get("/Training/GetTrainingMaterial?" + params);
  }
  async SaveOnlineTraining(params) {
    return http.instance.post("/Training/SaveOnlineTraining?", params);
  }
  async InsertCompanyPackage(params) {
    return http.instance.post("/MSPSubscription/InsertCompanyPackage", {postString: params}  );
  }
  async GetQuestionnaireData(params) { 
    return await http.instance.get("/Training/GetQuestionnaireData?" + params);
  }
  async AddQuestionnaire(params) {
    return http.instance.post("/Training/AddQuestionnaire?", params);
  }
  async GetCompanyInfoByBatchId(params) {
    return http.instance.get("/MSPSubscription/GetCompanyPackage?" + params);
  }
  async InsertsSubscription(params) {
    return await http.instance.post("/MSPSubscription/InsertsSubscription?", params);
  }
}
export default new DataService();
