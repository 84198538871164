import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/Training/Index",
        name: "TrainingListing",
        component: () => import("../views/Training/index.vue"),
        meta: {
          title: "Training",
          breadcrumb: [{ name: "Training" }],
        },
    },
    {
        path: "/MSPPackages/Index",
        name: "MSPPackages",
        component: () => import("../views/MSPPackages/Index.vue"),
        meta: {
          checkPrivilege: false,
          title: "Manage Package",
          breadcrumb: [{ name: "Manage Packages" }],
        }
    },
    {
        path: "/MSPSubscription/Index",
        name: "MSPSubscription",
        component: () => import("../views/MSPSubscription/Index.vue"),
        meta: {
          title: "MSP Subscription",
          breadcrumb: [{ name: "MSPSubscription" }],
          checkPrivilege: false,

        }
    },
    {      
        path: "/MSPPackages/AddPackageDetail/:id",
        name: "ManagePackage",
        component: () => import( "../views/MSPPackages/AddPackage.vue"),
        meta: { 
            checkPrivilege: false,
            title: "Add Package",
            breadcrumb:[{ name: "Manage Packages", link:"/MSPPackages/Index" }, {name: "Manage"}
        ],
        actionName: "ManagePackage" }
    },
    {
        path: "/Training/add",
        name: "AddTraining",
        component: () => import('../views/Training/ManageTraining.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Add Training',
            breadcrumb:[{ name: "Training", link:"/Training/Index" }, {name: "Manage"}],
        }
    },
    {      
        path: "/MSPSubscription/AddSubscriptionDetail/:postData?",
        name: "AddSubscription",
        component: () => import( "../views/MSPSubscription/AddSubscription.vue"),
        meta: { 
            checkPrivilege: false,
            title: "Add Subscription",
            breadcrumb:[{ name: "Manage Subscription", link:"/MSPSubscription/Index" }, {name: "Manage"}
        ],
        actionName: "AddSubscription" }
    },
    {      
        path: "/MSPSubscription/EditSubscriptionDetail/:id",
        name: "ManageSubscription",
        component: () => import( "../views/MSPSubscription/EditSubscriptionDetail.vue"),
        meta: { 
            checkPrivilege: false,
            title: "Add Subscription",
            breadcrumb:[{ name: "Manage Subscription", link:"/MSPSubscription/Index" }, {name: "Manage"}
        ],
        actionName: "ManageSubscription" }
    },
    {
        path: "/Training/Update/:id/:trainingRecurringId",
        name: "UpdateTraining",
        component: () => import('../views/Training/ManageTraining.vue'),
        meta: {
            title: 'Update Training',
            breadcrumb:[{ name: "Training", link:"/Training/Index" }, {name: "Manage"}],
            checkPrivilege: false,
        },
      },
      {
        path: "/Training/Trainingview/:trainingId/:recurringId",
        name: "TrainingDetail",
        component: () => import("../views/Training/TrainingDetail.vue"),
        meta: {
            title: 'Manage Training',
            breadcrumb: [
                { name: "Training", link: "/Training/Index" },
                { name: "View Training Detail" }
            ]
        },
    },
    {
        path: "/Training/StartTraining/:trainingId/:recurringId",
        name: "StartTraining",
        component: () => import("../views/Training/StartTraining.vue"),
        meta: {
            title: 'Start Training',
            breadcrumb: [
                { name: "Training", link: "/Training/Index" },
                { name: "View Training Detail" }
            ],
            checkPrivilege: false,
        },
    },
    {      
        path: "/MSPSubscription/SubscriptionReview/:poststring",
        name: "SubscriptionReview",
        component: () => import( "../views/MSPSubscription/SubscriptionReview.vue"),
        meta: { 
            checkPrivilege: false,
            title: "Review Subscription",
            breadcrumb:[{ name: "Add Subscription", link:"/MSPSubscription/SubscriptionReview/:poststring" }, {name: "Manage"}
        ],
        actionName: "SubscriptionReview" }
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/unauthorized') {
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if(to.meta.hasOwnProperty('checkPrivilege'))
            {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if(isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else{
                    let pathArray = to.path.split("/");
                    if(pathArray.length > 2){
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else{
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if(controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            //authService.authenticate(to.path).then(() => {
                console.log('authenticating a protected url:' + to.path);
                next();
           // });
        }
    }
});
export default router

